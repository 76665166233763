export const freshChatToken = '5b22d9a8-b5a5-495f-a14b-377ea47e867f';
export const freshChatHost = 'https://wchat.freshchat.com';
export const freshChatLibURL = 'https://wchat.freshchat.com/js/widget.js';
export const iframeChatClosedEvent = 'widget:closed';
export const iframeChatId = 'fc_frame';

export const initFreshChat = () => {
  window.fcWidget.init({
    config: {
      cssNames: {
        expanded: 'custom_fc_expanded',
        widget: 'custom_fc_frame',
        open: 'custom_fc_open',
      },
      headerProperty: {
        hideChatButton: true,
      },
    },
    token: freshChatToken,
    host: freshChatHost,
  });
  window.fcWidget.open();
};
