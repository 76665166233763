import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import Burger from './Burger';
import BottomButton from './BottomButton';
import { useModalOpen } from '../../shared/Modal';
import LoginForm from '../../Auth/Form/LoginForm';
import { StoreContext } from '../../../store';
import dictionary from '../../../utils/dictionary';
import { iframeChatId } from '../../Content/Chat/fresh-chat/FreshChatConfig';
import FreshChatMobileBtnBottom from '../../Content/Chat/fresh-chat/chat-btns/FreshChatBtnMobileBottom';

import profile from '../../../img/user-profile-bottom.svg';
import tel from '../../../img/tel-bottom.svg';
import line from '../../../img/line-bottom.svg';

const Wrapper = styled('div')`
  width: 100%;
  height: 60px;
  background-color: ${color.minsk};
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  
  & .divider {
    height: 28px;
    width: 2px;
    background-color: ${color.white};
    opacity: 0.2;
    border-radius: 100%;
    padding-bottom: 12px;
  }
  
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const BottomPanel = () => {
  const [open, setOpen] = useState(false);
  const [state] = useContext(StoreContext);
  const { user: { userName } } = state;

  const login = useModalOpen('bottom-login-popup');

  const toggleMenu = (e) => {
    setOpen(() => !open);
    e.preventDefault();
    const header = document.getElementById('header');
    const ul = document.getElementById('nav');
    const content = document.getElementById('all-content');
    const mq = window.matchMedia('(max-width: 960px)');
    ul.classList.toggle('open');
    content.classList.toggle('open');

    if (mq.matches) {
      header.style.cssText = `
        z-index: 9999;
        width: 100%;
        left: 0;
        top: 0;
        background-color: s${!open && '#2e3452'};
        width: 100vw;
      `;
    } else {
      header.style.cssText = '';
    }
  };

  const openFreshChatHandler = () => {
    const chatWindow = document.getElementById(iframeChatId);
    if (chatWindow) {
      chatWindow.style.visibility = 'visible';
    }
    window?.fcWidget.open();
  };

  return (
    <Wrapper>
      <Burger onClick={toggleMenu} open={open} />
      <div className="divider" />
      {
        userName
          ? (
            <BottomButton to="/profile/">
              <img className="iconImage" src={profile} alt="profile" />
              <span>{dictionary.profile}</span>
            </BottomButton>
          )
          : (
            <BottomButton onClick={login}>
              <img className="iconImage" src={profile} alt="profile" />
              <span>{dictionary.login}</span>
            </BottomButton>
          )
      }
      <div className="divider" />
      <BottomButton to="tel:025088798" target="_blank">
        <img className="iconImage" src={tel} alt="tel" />
        <span>โทรศัพท์</span>
      </BottomButton>
      <div className="divider" />
      <BottomButton to="https://line.me/R/ti/p/%40yvx7063g" target="_blank">
        <img className="iconImage" src={line} alt="line" />
        <span>Line</span>
      </BottomButton>
      <div className="divider" />
      <BottomButton onClick={openFreshChatHandler}>
        <FreshChatMobileBtnBottom />
        <span>Chat</span>
      </BottomButton>
      <LoginForm popupId="bottom-login-popup" />
    </Wrapper>
  );
};

export default BottomPanel;
