import React, { useState } from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../../utils/style';
import { freshChatLibURL, iframeChatClosedEvent, initFreshChat } from './FreshChatConfig';
import FreshChatBtnIcon from './chat-btns/FreshChatBtnIcon';
import loaderImage from '../../../../img/withdraw/loader.gif';

const Wrap = styled('div')`
  .live-chat {
    bottom: 10px;
    position: fixed;
    right: 10px;
    background: ${color.chatBtnColor};
    padding: 5px 30px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 35px;
    color: ${color.black};
    cursor: pointer;
    outline:none;
    border:none;
    border-radius: 25px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    height: 45px;

    button {
      background: none;
      border: none;
      outline: none;
      font-size: 1rem;
      cursor: pointer;
    }
    
    svg {
      margin-right: 10px;
      vertical-align: middle;
    }
  }
  
  .loader {
    max-width: 20px;
    margin: 0;
    transition-delay: 5s;
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const FreshChatComponent = () => {
  const [loadedChatScript, setLoadedChatScript] = useState(false);
  const [chatIframeOpen, setChatIframeOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const initChat = () => {
    initFreshChat();

    window.fcWidget.on(iframeChatClosedEvent, () => {
      setChatIframeOpen(false);
    });

    setTimeout(() => {
      setShowLoader(false);
      setLoadedChatScript(true);
      setChatIframeOpen(true);
    }, 1000);
  };

  const initFreshChatLib = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = freshChatLibURL;
    script.id = 'freshchat-lib';
    document.head.appendChild(script);
    script.onload = () => initChat();
  };

  const openCustomWidget = () => {
    if (!loadedChatScript) {
      setShowLoader(true);
      initFreshChatLib();
    } else {
      window.fcWidget.open();
      setChatIframeOpen(true);
    }
  };

  return (
    <Wrap id="chat-wrapper">
      {!chatIframeOpen && (
        <div className="live-chat" id="live-chat" onClick={openCustomWidget}>
          <button>
            <FreshChatBtnIcon color={color.darkGrey} size={16} />
            <span>แชทสด</span>
          </button>
          {!loadedChatScript && showLoader && (
            <img src={loaderImage} alt="loader.gif" className="loader" />
            )
          }
        </div>
      )}
    </Wrap>
  );
};
export default FreshChatComponent;
